/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:bc663cd1-624d-4471-a10d-ec2d065285e6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_zc8ohtXQd",
    "aws_user_pools_web_client_id": "3svj6qggfnuc5h4e9vi06tqb03",
    "oauth": {}
};


export default awsmobile;

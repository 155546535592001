import { Media } from "../../../models/media.model";
import { ApiService } from "../base/api.service";

export class MediaService extends ApiService<Media> {
    protected endpoint = 'media';

    constructor() {
        super('media')
    }

    public async fileUpload<TResponseType>(
        files: any,
        path: Array<string> = []
      ): Promise<TResponseType> {
        const res = await fetch(`${this.apiUrl}/${path.join('/')}`, {
          method: 'POST',
          body: files
        });
    
        return res.json();
      }

    public async addLinkedAccount(entity: any, path: Array<string> = []) {
      const res = await fetch(`${this.apiUrl}/${path.join('/')}`, {
        method: 'PATCH',
        body: JSON.stringify(entity),
        headers: {'Content-Type': 'application/json'}
      })
      
      return res.json()
    }
}
import { useEffect, useState } from 'react';
import { AdsService } from '../../services/api/ads/ads.service';
import { MediaService } from '../../services/api/media/media.service';

const adsService = new AdsService();
const mediaService = new MediaService();

const AddLinkedAccount = (props: any) => {
  const [adAccounts, setAdAccounts] = useState<any[]>();

  const buildAccountOptions = (adAccounts: any[]) => {
    if (!adAccounts.length) {
      return;
    }

    return adAccounts.map((item, index) => {
      return (
        <option key={index} value={JSON.stringify(item)}>
          {item.name}
        </option>
      );
    });
  };

  const submit = async (event: any) => {
    event.preventDefault();

    const formElement: HTMLFormElement | null = document.querySelector('#linkedAdAccountForm');
    
    if (!formElement) {
      return;
    }

    const formData = new FormData(formElement)
    let adAccount = formData.get('adAccount') as string

    if (!!adAccount) {
      adAccount = JSON.parse(adAccount)
    }

    const data = {
      adAccount: adAccount,
      mediaURL: formData.get('mediaURL')
    }

    const response = await mediaService.addLinkedAccount(data, ['add-linked-account', props.media._id])
    console.log(response)
    if (response.statusCode !== 200) {
      alert(response.response.message || 'An error has occurred while linking media')
      return;
    }

    window.location.reload()
  }

  const initialize = async () => {
    const adAccounts = await adsService.get(['adaccounts']);
    setAdAccounts(adAccounts);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div className="mca">
      <form id="linkedAdAccountForm" className="f fdc mca w25p">
        {!!adAccounts && (
          <div style={{ textAlign: 'left', width: '250px' }}>
            <label htmlFor="adAccounts" style={{ fontWeight: 'bold' }}>
              Ad Account
            </label>
            <select
              className="my-05 w100p"
              name="adAccount"
              id="adAccounts"
              // onChange={handleAdAccountSelect}
              // value={adAccount.value}
              style={{ display: 'block'}}
            >
              <option value="" disabled selected>Select Ad Account</option>
              {buildAccountOptions(adAccounts)}
            </select>
          </div>
        )}
        <div style={{ textAlign: 'left',  width: '250px'  }}>
          <label htmlFor="mediaURL" style={{ fontWeight: 'bold' }}>
            Media URL
          </label>
          <input
            className="my-05 w100p"
            name="mediaURL"
            type="text"
            placeholder="Enter media URL"
            style={{ display: 'block', boxSizing: 'border-box'}}
          />
        <button className="my-1 btn btn-md btn-primary w100p" onClick={submit} style={{ display: 'block'}}>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default AddLinkedAccount;

import { useEffect, useState } from 'react';
import './App.css';

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import {
  AmplifyAuthenticator,
  AmplifySignIn,
} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AdsComponent from './components/ads';
import MediaComponent from './components/media';
import HistoryComponent from './components/history';
import MediaDetailsComponent from './components/media-details';
import HistoryDetailsComponent from './components/history-details';
import MediaUploadComponent from './components/media-upload';
import NavigationComponent from './components/navigation';
import AddLinkedAccount from './components/add-linked-account';

Amplify.configure(awsconfig);

function App() {
  const [authState, setAuthState] = useState<any>();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState) => {
      setAuthState(nextAuthState);
    });
  });

  return authState === AuthState.SignedIn
  // return !!true
    ? (
    <Router>
      <div className="App">
        <main className="f fdc w50p mca">
          <NavigationComponent />
          <Switch>
            <Route exact path="/" component={AdsComponent}></Route>
            <Route exact path="/media" component={MediaComponent}></Route>
            <Route exact path="/media/upload" component={MediaUploadComponent}></Route>
            <Route exact path="/media/linked-accounts" component={AddLinkedAccount}></Route>
            <Route exact path="/media/:id" component={MediaDetailsComponent}></Route>
            <Route exact path="/history" component={HistoryComponent}></Route>
            <Route exact path="/history/:id" component={HistoryDetailsComponent}></Route>
          </Switch>
        </main>
      </div>
    </Router>
  )
  : (
    <AmplifyAuthenticator
      initialAuthState={AuthState.SignIn}
      usernameAlias="email"
    >
      <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp={true} />
    </AmplifyAuthenticator>
  )
}

export default App


import { Auth } from 'aws-amplify';

// const API_URL = 'http://localhost:3001/v1';
const API_URL = 'https://api.calebtool.com/v1';

const getAuthToken = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user.signInUserSession.idToken.jwtToken;
}

const interceptor = async (fetch: any, ...ogArgs: any): Promise<any> => {
  let [url, args] = ogArgs

  if(!args) {
    args = {}
  }

  if(url.includes(API_URL)) {
    if (!args.headers) {
      args.headers = {'Authorization' : `Token ${await getAuthToken()}`}
    } else {
      args.headers = Object.assign(args.headers || {}, {'Authorization' : `Token ${await getAuthToken()}`})
    }
    
  }

  return fetch(url, args)
}

window.fetch = (function (fetch) {
  return function (...args: any) {
    return interceptor(fetch, ...args);
  };
})(window.fetch)


export class ApiService<TModel> {
    public apiUrl: string;
  
    constructor(endpoint: string) {
      endpoint = endpoint.replace('/', '')
      this.apiUrl = `${API_URL}/${endpoint}`
    }
  
    public async get<TResponseType = TModel>(
      path: Array<string> = [],
    ): Promise<TResponseType> { 
      const res = await fetch(`${this.apiUrl}/${path.join('/')}`);
      return res.json();
    }
  
    public async query<TResponseType = TModel>(
      query: any,
      path: Array<string> = []
    ): Promise<TResponseType> {
      const queryParams = Object.entries(query)
        .map((param) => {
          return `${param[0]}=${param[1]}`;
        })
        .join('&');
      const res = await fetch(`${this.apiUrl}/${path.join('/')}?${queryParams}`, {method: 'GET'});
      return res.json();
    }
  
    public async update<TResponseType = TModel>(
      entity: any,
      path: Array<string> = []
    ): Promise<TResponseType | any> {
      const res = await fetch(`${this.apiUrl}/${path.join('/')}`, {
        method: 'POST',
        body: JSON.stringify(entity),
        headers: {'Content-Type': 'application/json'}
      });

      if (res.status > 400) {
        console.log(await res.json())
        return null
      }

      res.json();
    }

    public async delete (
      path: Array<string> = [],
    ): Promise<any> { 
      const res = await fetch(`${this.apiUrl}/${path.join('/')}`, {method: 'DELETE'});
      const resJSON = await res.json();

      if (res.status === 200 && resJSON.deletedCount > 0) {
        return {
          success: true,
          message: 'Successfully deleted'
        }
      } else {
        return {
          success: false,
          message: 'An error occurred while deleting item.'
        }
      }
    }
  
}
  
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom";
import { HistoryService } from "../../services/api/history/history.service"
import { formatLocaleTimestamp } from '../../utils'

const historyService = new HistoryService();

let REFRESH: any;

const HistoryComponent = () => {
    const [history, setHistory] = useState<{
      lastFetched: any;
      page: number;
      data: any[];
      isLastPage: boolean;
    }>({ lastFetched: undefined, page: 0, data: [], isLastPage: false });

    const navigation = useHistory();
    const currentPage = useRef(history.page);

    const navigateTo = (event: any) => {
        navigation.push(event.target.value);
    }

    const fetchPage = async (page: number) => {
        const data: any[] = await historyService.query({sort: 'createdAt:desc', page, limit: 10});
        const lastFetched = `${new Date().toLocaleTimeString()}`
        
        if (!!data && data.length) {
            setHistory({lastFetched, page, data, isLastPage: false});
        }

        if (!data.length) {
            setHistory({...history, isLastPage: true})
        }
        return;
    }

    const initialize = async () => {
        if(!currentPage.current) {
            await fetchPage(0);
        }
    }

    useEffect(() => {
        currentPage.current = history.page
    })

    useEffect(() => {
        initialize();
        REFRESH = setInterval(() => {initialize()}, 5000)
        return () => {
            clearInterval(REFRESH)
        }
    }, [])

    return (
        <>
            <h1>History</h1>
            {!!history && (
                <>
                <div style={{marginBottom: '1em'}}><strong>Last Fetched: </strong>{history.lastFetched}</div>
                <table className="mca">
                    <thead>
                        <tr>
                            <th>
                                Type
                            </th>
                            <th>
                                Timestamp
                            </th>
                            <th>
                                Status
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {history.data.map((item) => {
                            const path = `/history/${item._id}`;
                            return (
                                <tr key={item._id}>
                                    <td>{item.type}</td>
                                    <td>{formatLocaleTimestamp(item.createdAt)}</td>
                                    <td>{item.status}</td>
                                    <td><button value={path} onClick={navigateTo}>Details</button></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="my-1">
                    <div className="mb-05">Page: {history.page + 1}</div>
                    {history.page !== 0 && <button className="mx-05" onClick={() => fetchPage(history.page - 1)}>Back</button>}
                    {!history.isLastPage && <button className="mx-05" onClick={() => fetchPage(history.page + 1)}>Next</button>}
                </div>
                </>
            )}
        </>
    )
}

export default HistoryComponent
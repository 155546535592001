import { useEffect, useState } from "react";
import { useParams } from "react-router"
import { HistoryService } from "../../services/api/history/history.service";
import { formatLocaleTimestamp } from '../../utils'

const historyService = new HistoryService()

const HistoryDetailsComponent = () => {
    const {id} = useParams<{id: string}>();

    const [history, setHistory] = useState<any>()

    const initialize = async () => {
        const data: any = await historyService.get([id])

        if(!!data) {
            setHistory(data);
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    return (
        <>
            <h1>History Item</h1>
            {!!history && (
                <div style={{margin: '0 auto', textAlign: 'left', width: '60em', wordBreak: 'break-all'}}>
                    <table>
                        <tbody className="details-table">
                            <tr>
                                <td>Creation Id</td>
                                <td>{history.creationId}</td>
                            </tr>
                            <tr>
                                <td>Created At</td>
                                <td>{formatLocaleTimestamp(history.createdAt)}</td>
                            </tr>
                            <tr>
                                <td>Type</td>
                                <td>{history.type}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{history.status}</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>Errors</h2>
                    <pre>
                        {/* <code> */}
                            {JSON.stringify(history.creationErrors, null, 2)}
                            {/* {JSON.stringify(history.creationErrors, null, "\t")} */}
                        {/* </code> */}
                    </pre>
                </div>
            )}
        </>
    )
}

export default HistoryDetailsComponent
import { Amplify } from 'aws-amplify';
import { Link } from "react-router-dom"

// TODO: Add logout

const NavigationComponent = () => {

    const logout = () => {
        try {
            Amplify.Auth.signOut();
            window.location.href = window.location.origin;
        } catch (err) {
            console.log('error')
        }
    }

    return (
        <div style={{display: 'flex', margin: '1.5em auto', width: '60em'}}>
            <div>    
                <span className="nav-item"><Link to="/">Home</Link></span>
                <span className="nav-item"><Link to="/media">Media</Link></span>
            </div>
            <div className="mla">
                <span className="nav-item" onClick={logout}>Logout</span>
            </div>
        </div>
    )
}

export default NavigationComponent
import { useEffect, useState } from "react"
import { AdsService } from "../../services/api/ads/ads.service";
import HistoryComponent from "../history";

const adsService = new AdsService();

const AdsComponent = () => {
    const [file, setFile] = useState<any>()
    const [adType, setAdType] = useState<'' | 'webview' | 'snapstory'>('')
    const [message, setMessage] = useState<string>('')

    const handleFileUploadChange = (event: any) => {
        const file = event.target.files[0];
        setFile(file)
    }

    const handleAdTypeSelect = (event: any) => {
        setAdType(event.target.value)
    }
    
    const handleFileUpload = async (event: any) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('file', file, file.name)

        const data: any = await adsService.fileUpload(formData, ['upload', adType])

        if (data.statusCode === 200) {
            setMessage(data.message)
            setTimeout(() => {
                setMessage('')
            }, 5000)
        }

        setAdType('')
    }

    const initialize = async () => {}

    useEffect(() => {
        initialize();
    })

    return (
        <>
            <h1>Ads</h1>
            <div className="">
                <form className="f fdc mca w25p">
                    <input type="file" onChange={handleFileUploadChange}/>
                    <select className="my-05" name="adType" id="adType" onChange={handleAdTypeSelect} value={adType}>
                        <option value=""></option>
                        <option value="webview">Web View</option>
                        <option value="snapstory">Snap Story</option>
                    </select>
                    <div>
                        <button type="submit" onClick={handleFileUpload}>Submit</button>
                    </div>
                </form>
                <div className="my-05">{message}</div>
            </div>
            <div>
                <br/>
                <hr/>
                <HistoryComponent />
            </div>
        </>
    )
}

export default AdsComponent
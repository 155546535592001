import { useEffect, useState } from "react"
import { Media } from "../../models/media.model"
import { AdsService } from "../../services/api/ads/ads.service"
import { MediaService } from "../../services/api/media/media.service"

import { formatLocaleTimestamp } from "../../utils";

const adsService = new AdsService()
const mediaService = new MediaService()

let REFRESH: any;

const buildAccountOptions = (adAccounts: any[]) => {
    if(!adAccounts.length) {
        return
    }

    return adAccounts.map((item, index) => {
        return (
            <option key={index} value={item.id}>{item.name}</option>
        )
    })
}

const MediaUploadComponent = () => {
    const [adAccounts, setAdAccounts] = useState<any[]>();
    const [selectedAdAccounts, setSelectedAdAccounts] = useState<any[]>([]);
    const [adAccount, setAdAccount] = useState<any>('');
    const [files, setFiles] = useState<any[]>();
    const [mediaType, setMediaType] = useState<''| 'SNAP' | 'THUMB' | 'LOGO'>('');
    const [message, setMessage] = useState<string>('')
    const [currentUpload, setCurrentUpload] = useState<any[]>([])

    const resetDropdown = (selector: string) => {
        const adAccountDropdown = document.querySelector(selector) as HTMLSelectElement;
        adAccountDropdown!.selectedIndex = -1
    }

    const fetchCurrentUpload = async (page: number) => {
        const data: any[] = await mediaService.query({sort: 'createdAt:desc', page, limit: 20});
        if (!!data && data.length) {
            const uploadingMedia: Media[] = data.filter(mediaItem => {
                return !!mediaItem.linkedAdAccounts.find((account: any) => account.status === 'PENDING_UPLOAD')
            })

            const flattenedUploadData = uploadingMedia.reduce((baseValue, mediaItem) => {
                const uploadingItems = mediaItem.linkedAdAccounts
                    .filter(item => item.status === 'PENDING_UPLOAD')
                    .map(item => {
                        return {snapId: mediaItem.snapId, type: mediaItem.internalMediaType, ...item}
                    });

                baseValue.push(...uploadingItems)
                return baseValue
            }, [] as Array<any>)

            setCurrentUpload(flattenedUploadData)
        }

        return;
    }

    const handleFileSelect = (event: any) => {
        const files = event.target.files;
        setFiles(files)
    }

    const handleFileUpload = async (event: any) => {
        event.preventDefault();

        const formData = new FormData();

        if(!files) {
            return;
        }

        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i], files[i].name)
          }
        
        formData.append('adAccounts', JSON.stringify(selectedAdAccounts))
        formData.append('internalMediaType', mediaType)

        // TODO: Handle Exists
        const data: any = await mediaService.fileUpload(formData, ['upload'])

        if (data.statusCode === 200) {

            setMessage(data.message);
            clearInterval(REFRESH)
            REFRESH = setInterval(() => initialize(), 5000)

            setTimeout(() => {
                setMessage('')
            }, 1000);
        }

        setAdAccount({value: ''})
        setMediaType('')
        setSelectedAdAccounts([])
    }

    const handleMediaTypeSelect = (event: any) => {
        setMediaType(event.target.value)
    }

    const handleAdAccountSelect = (event: any) => {
        const adAccount = adAccounts?.find(account => {
            return account.id === event.target.value
        })

        const selectedAdAccount = selectedAdAccounts?.find(account => {
            return account.id === event.target.value
        })

        if(!selectedAdAccount) {
            setSelectedAdAccounts([...selectedAdAccounts, adAccount])
        }
    }

    const removeAdAccount = (adAccountId: string) => {
        const adAccounts = selectedAdAccounts.filter(item => {
            return item.id !== adAccountId
        });

        setSelectedAdAccounts(adAccounts);
        resetDropdown('adAccounts');
    }

    const initialize = async () => {
        const adAccounts = await adsService.get(['adaccounts'])
        setAdAccounts(adAccounts)
        await fetchCurrentUpload(0);
    }

    useEffect(() => {
        initialize();
        REFRESH = setInterval(() => initialize(), 5000)
        return () => {
            clearInterval(REFRESH)
        }
    }, [])

    return (
        <>
            <h1>Media Upload</h1>
            <form className="f fdc mca w25p">
                <input className="mb-05" type="file" id="mediaFile" onChange={handleFileSelect} multiple/>
                <select id="mediaTypeUpload" className="mb-05" required onChange={handleMediaTypeSelect} value={mediaType}>
                    <option value="" disabled selected>Select Media Type</option>
                    <option value="SNAP">Snap</option>
                    <option value="THUMB">Thumbnail</option>
                    <option value="LOGO">Logo</option>
                </select>
                {!!adAccounts && (
                    <select className="mb-05" name="adAccounts" id="adAccounts" onChange={handleAdAccountSelect} value={adAccount.value}>
                        <option value="" disabled selected>Select Ad Account</option>
                        {buildAccountOptions(adAccounts)}
                    </select>
                )}
                {!!selectedAdAccounts.length && (
                    <div className="my-1">
                        <div style={{marginBottom: '0.5em'}}><strong>Selected Ad Accounts</strong></div>
                        <div>
                        {selectedAdAccounts.map((adAccount) => {
                                return <div key={adAccount.id} className="f jc-sb mb-025">{adAccount.name}  <img className="icon" src="./trash.svg" alt="[X]" onClick={() => removeAdAccount(adAccount.id)}/></div>
                            })}
                        </div>
                    </div>
                )}
                <div>
                    <button type="submit" onClick={handleFileUpload}>Submit</button>
                </div>
            </form>
            <div className="my-05">{message}</div>
            {!!currentUpload.length && (
                <div>
                    {/* <hr /> */}
                    <h2>Current Upload</h2>
                    <div style={{marginBottom: '1em'}}>
                        <table className="mca" style={{width: '60em'}}>
                            <thead>
                                <tr>
                                    <th>Snap Id</th>
                                    <th>Type</th>
                                    <th>Timestamp</th>
                                    <th>Status</th>
                                    <th>Ad Account</th>
                                </tr>
                            </thead>
                            <tbody>
                            {currentUpload.map(item => {
                                return (
                                    <tr>
                                        <td>{item.snapId}</td>
                                        <td>{item.type}</td>
                                        <td>{formatLocaleTimestamp(item.createdAt.toLocaleString())}</td>
                                        <td>{item.status}</td>
                                        <td>{item.name}</td>
                                    </tr>
                                    )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
            <br />
            <hr />
        </>
    )
}

export default MediaUploadComponent
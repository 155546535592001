import { ApiService } from "../base/api.service";

export class AdsService extends ApiService<any> {
    // protected endpoint = 'ads'
    
    constructor() {
        super('ads')
    }

    public async fileUpload<TResponseType>(
        file: any,
        path: Array<string> = []
      ): Promise<TResponseType> {
        const res = await fetch(`${this.apiUrl}/${path.join('/')}`, {
          method: 'POST',
          body: file
        });
    
        return res.json();
      }
}
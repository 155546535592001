import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Media } from '../../models/media.model';
import { MediaService } from '../../services/api/media/media.service';
import AddLinkedAccount from '../add-linked-account';

const mediaService = new MediaService();

const MediaDetailsComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const [media, setMedia] = useState<Media>();

  const buildAsset = (media: Media) => {
    if (!media.downloadLink) {
      return;
    }

    if (media.type !== 'IMAGE') {
      return (
        <video controls width="100%" height="auto">
          <source src={media.downloadLink} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }
    return (
      <img src={media.downloadLink} width="100%" height="auto" alt="asset" />
    );
  };

  const deleteMedia = async (event: any) => {
    const confirmed = window.confirm(
      `All data for media item will be deleted for ${media?.snapId}. \nDo you want to continue?`
    );

    if (confirmed) {
      const response: any = await mediaService.delete([media!._id]);
      if (response.success) {
        history.goBack();
      }
    }
  };

  const deleteLinkedAdAccount = async (id: string) => {
    if (!media) {
      return;
    }

    media.linkedAdAccounts = media?.linkedAdAccounts.filter((item) => {
      return item._id != id;
    });

    let updatedMedia = await mediaService.update(media);

    if (!updatedMedia) {
      alert('An error occurred while attempting to remove linked account.');
      return;
    }

    setMedia({ ...updatedMedia });
  };

  const initialize = async () => {
    const data: Media = await mediaService.get([id]);
    setMedia(data);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <h1>Media Details</h1>
      {!!media && (
        <div style={{ display: 'flex', margin: '0 auto', maxWidth: '60em' }}>
          <div style={{ width: '15em' }}>{buildAsset(media)}</div>
          <div style={{ minWidth: '15em', marginLeft: '1em' }}>
            <table>
              <tbody className="details-table">
                <tr>
                  <td>Snap Id</td>
                  <td>{media.snapId}</td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td>{media.originalName}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <h2>Linked Ad Accounts</h2>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>CreatedAt</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!!media.linkedAdAccounts && (
                    <>
                      {media.linkedAdAccounts.map((item) => {
                        return (
                          <tr key={item._id}>
                            <td>{item.name}</td>
                            <td>{item.status}</td>
                            <td>
                              {new Date(item.createdAt).toLocaleDateString() ||
                                'No Data'}
                            </td>
                            <td>
                              <img
                                className="icon"
                                src="/trash.svg"
                                alt="delete"
                                onClick={() => deleteLinkedAdAccount(item._id)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {!!media && (
        <div className="mt-1" style={{ maxWidth: '60em' }}>
          <hr />
          <AddLinkedAccount media={media} />
          <hr />
        </div>
      )}
      <div className="my-1">
        <button className="btn btn-lg btn-danger" onClick={deleteMedia}>
          Delete Media
        </button>
      </div>
    </>
  );
};

export default MediaDetailsComponent;

import { useEffect, useRef, useState } from "react"
import { Media } from "../../models/media.model";
import { MediaService } from "../../services/api/media/media.service"

import { useHistory } from "react-router-dom";
import { formatLocaleTimestamp } from "../../utils";
import MediaUploadComponent from "../media-upload";

const mediaService = new MediaService();

let REFRESH: any;

const MediaComponent = () => {
    const [media, setMedia] = useState<{
      lastFetched: any;
      page: number;
      data: Media[];
      isLastPage: boolean;
    }>({ lastFetched: undefined, page: 0, data: [], isLastPage: false });

    const history = useHistory<any>();
    const currentPage = useRef(media.page);
    const currentType = useRef('SNAP');

    const navigateTo = (event: any) => {
        console.log({pathname: event.target.value, state: {type: currentType.current}})
        history.push({pathname: event.target.value, state: {type: currentType.current}});
    }

    const setDropdownSelection = (selector: string, value: string) => {
        const dropdown = document.querySelector(selector) as HTMLSelectElement;
        const options = Array.from(dropdown.options)
        const index = options.findIndex((option) => option.value === value)
        dropdown!.selectedIndex = index;
    }


    const fetchPage = async (page: number) => {
        const data: any[] = await mediaService.query({internalMediaType: currentType.current, sort: 'createdAt:desc', page, limit: 10});
        // const data: Media[] = await mediaService.query({internalMediaType: type, sort: 'createdAt:desc'})
        const lastFetched = `${new Date().toLocaleTimeString()}`
        
        if (!!data && data.length) {            
            setMedia({lastFetched, page, data, isLastPage: false});
        }

        if (!data.length) {
            setMedia({...media, isLastPage: true})
        }
        return;
    }

    const initialize = async () => {
        currentType.current = history.location.state?.type || 'SNAP';
        setDropdownSelection('#mediaType', currentType.current)
        if(!currentPage.current) {
            await fetchPage(0);
        }
    }

    useEffect(() => {
        currentPage.current = media.page
        // currentType.current = history.location.state?.type || 'SNAP';
    })

    const handleMediaTypeSelect = (event: any) => {
        currentType.current = event.target.value;
        history.replace({pathname: '/media', state: {type: currentType.current}})
        clearInterval(REFRESH)
        initialize()
        REFRESH = setInterval(() => initialize(), 5000)
    }

    useEffect(() => {
        initialize();
        REFRESH = setInterval(() => initialize(), 5000)
        return () => {
            clearInterval(REFRESH)
        }
    }, [])

    return (
        <>
            {/* <h1>Media Upload</h1> */}
            <div>
                {/* <button value="/media/upload" onClick={navigateTo}>Upload</button> */}
                <MediaUploadComponent />
            </div>
            {!!media && (
                <div>
                    <h1>Media Items</h1>
                    <div style={{margin: '1em 0'}}>
                        <strong>Type: </strong>
                        <select id="mediaType" className="" defaultValue="SNAP" required onChange={handleMediaTypeSelect}>
                            <option value="SNAP">Snap</option>
                            <option value="THUMB">Thumbnail</option>
                            <option value="LOGO">Logo</option>
                        </select>
                        {/* {typeDropdown()} */}
                    </div>
                    <div style={{marginBottom: '1em'}}><strong>Last Fetched: </strong>{media.lastFetched}</div>
                    <table className="mca">
                        <thead>
                            <tr>
                                <th>Snap Id</th>
                                <th>Type</th>
                                <th>Timestamp</th>
                                {/* <th>Status</th>
                                <th>Ad Account</th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {media.data.map((item) => {
                                const path = `/media/${item._id}`;
                                return (
                                    <tr key={item._id}>
                                        <td>{item.snapId}</td>
                                        <td>{item.internalMediaType}</td>
                                        <td>{formatLocaleTimestamp(item.createdAt.toLocaleString())}</td>
                                        {/* <td>{item.mediaStatus}</td>
                                        <td>{item.adAccountName}</td> */}
                                        <td><button value={path} onClick={navigateTo}>Details</button></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <div className="my-1">
                        <div className="mb-05">Page: {media.page + 1}</div>
                        {media.page !== 0 && <button className="mx-05" onClick={() => fetchPage(media.page - 1)}>Back</button>}
                        {!media.isLastPage && <button className="mx-05" onClick={() => fetchPage(media.page + 1)}>Next</button>}
                    </div>
                </div>
                
            )}
        </>
    )
}

export default MediaComponent